<template>
  <div class="admin-presse custom-container">
    <div class="presse-table-container">
      <div class="header-wrapper">
        <h4 class="title">
          Presse
        </h4>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Tippe um zu suchen"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Klar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="table-box">
        <b-table
          :items="items"
          :fields="fields"
          :filter="filter"
          :busy="isLoading"
          bordered
          responsive
          striped
          hover
          show-empty
        >
          <template v-slot:head(function)>
            <b-btn
              variant="tab-orange"
              size="sm"
              @click="onCreatePresse"
            >
              Neu
            </b-btn>
          </template>
          <template #table-busy>
            <div class="text-center text-tab-orange my-4">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template v-slot:empty>
            <div class="empty-slot">
              <h4>Keine Presse verfügbar.</h4>
            </div>
          </template>
          <template v-slot:cell(createdDatetime)="{ value }">
            {{ value.split(' ')[0] | germanDate }}
          </template>
          <template v-slot:cell(url)="{ item, value }">
            <b-btn
              v-if="value === ''"
              variant="tab-orange"
              size="sm"
              @click="onDownloadItem(item)"
            >
              Herunterladen
            </b-btn>
            <a
              v-else
              :href="value"
              target="_blank"
              rel="noopener noreferrer"
              class="url-link"
            >
              {{ value }}
            </a>
          </template>
          <template v-slot:cell(information)="{ value }">
            <p v-html="value" />
          </template>
          <template v-slot:cell(function)="{ item }">
            <div class="function-td">
              <button @click="onEdit(item)">
                <icon-pencil />
              </button>
              <button @click="onDelete(item.identification)">
                <icon-trash />
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <base-modal
      id="admin-edit-presse-modal"
      title="Edit Presse"
      custom-class="admin-add-presse-modal"
      size="xl"
      admin
      no-close-on-backdrop
    >
      <b-row class="add-presse-form">
        <b-col :md="inputPresse.url.col">
          <b-form-group
            :label="inputPresse.url.label"
            :label-for="`input-${inputPresse.url.key}`"
            :state="isErrorInputPresse[inputPresse.url.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.url.key]"
          >
            <b-input-group>
              <b-form-input
                :id="`input-${inputPresse.url.key}`"
                v-model="inputPresse[inputPresse.url.key].value"
                :disabled="Boolean(inputPresse[inputPresse.filename.key].value)"
                @input="handleUrlAndFileInput"
              />
              <b-input-group-append>
                <b-btn @click="inputPresse[inputPresse.url.key].value = ''">
                  klar
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :md="inputPresse.filename.col">
          <b-form-group
            :label="inputPresse.filename.label"
            :label-for="`input-${inputPresse.filename.key}`"
            :state="isErrorInputPresse[inputPresse.filename.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.filename.key]"
            description="Akzeptieren Sie nur JPG, JPEG, PDF, PNG - Maximum 5 MB"
          >
            <b-form-file
              :id="`input-${inputPresse.filename.key}`"
              v-model="inputPresse[inputPresse.filename.key].value"
              :disabled="inputPresse[inputPresse.url.key].value !== ''"
              placeholder="Wählen Sie eine Datei oder legen Sie sie hier ab..."
              drop-placeholder="Datei hier ablegen..."
              @input="handleFileInput"
            />
          </b-form-group>
          <b-btn
            v-if="Boolean(inputPresse[inputPresse.filename.key].value)"
            size="sm"
            @click="inputPresse[inputPresse.filename.key].value = null"
          >
            Datei löschen
          </b-btn>
        </b-col>
        <b-col :md="inputPresse.information.col">
          <b-form-group
            :label="inputPresse.information.label"
            :label-for="`input-${inputPresse.information.key}`"
            :state="isErrorInputPresse[inputPresse.information.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.information.key]"
          >
            <b-form-textarea
              :id="`input-${inputPresse.information.key}`"
              v-model="inputPresse[inputPresse.information.key].value"
              rows="5"
              @input="isErrorInputPresse[inputPresse.information.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitEditPresse"
      >
        EINREICHEN
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-add-presse-modal"
      title="Add Presse"
      custom-class="admin-add-presse-modal"
      size="xl"
      admin
      no-close-on-backdrop
    >
      <b-row class="add-presse-form">
        <b-col :md="inputPresse.url.col">
          <b-form-group
            :label="inputPresse.url.label"
            :label-for="`input-${inputPresse.url.key}`"
            :state="isErrorInputPresse[inputPresse.url.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.url.key]"
          >
            <b-input-group>
              <b-form-input
                :id="`input-${inputPresse.url.key}`"
                v-model="inputPresse[inputPresse.url.key].value"
                :disabled="Boolean(inputPresse[inputPresse.filename.key].value)"
                @input="handleUrlAndFileInput"
              />
              <b-input-group-append>
                <b-btn @click="inputPresse[inputPresse.url.key].value = ''">
                  klar
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :md="inputPresse.filename.col">
          <b-form-group
            :label="inputPresse.filename.label"
            :label-for="`input-${inputPresse.filename.key}`"
            :state="isErrorInputPresse[inputPresse.filename.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.filename.key]"
            description="Akzeptieren Sie nur PNG, JPG, PDF - Maximum 5 MB"
          >
            <b-form-file
              :id="`input-${inputPresse.filename.key}`"
              v-model="inputPresse[inputPresse.filename.key].value"
              :disabled="inputPresse[inputPresse.url.key].value !== ''"
              placeholder="Wählen Sie eine Datei oder legen Sie sie hier ab..."
              drop-placeholder="Datei hier ablegen..."
              @input="handleFileInput"
            />
          </b-form-group>
          <b-btn
            v-if="Boolean(inputPresse[inputPresse.filename.key].value)"
            size="sm"
            @click="inputPresse[inputPresse.filename.key].value = null"
          >
            Datei löschen
          </b-btn>
        </b-col>
        <b-col :md="inputPresse.information.col">
          <b-form-group
            :label="inputPresse.information.label"
            :label-for="`input-${inputPresse.information.key}`"
            :state="isErrorInputPresse[inputPresse.information.key]"
            :invalid-feedback="errorMsgInputPresse[inputPresse.information.key]"
          >
            <b-form-textarea
              :id="`input-${inputPresse.information.key}`"
              v-model="inputPresse[inputPresse.information.key].value"
              rows="5"
              @input="isErrorInputPresse[inputPresse.information.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitAddPresse"
      >
        EINREICHEN
      </b-btn>
    </base-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-presse.scss";
</style>

<script>
require('@/plugins/datejs/date-de-DE');

import IconTrash from '@/components/icons/IconTrash';
import IconPencil from '@/components/icons/IconPencil';
import { germanDate } from '@/helpers/filters/germanDate';
import { AdminInformation } from '@/services';
import { loader } from '@/plugins/loader';
import {
  presseTableFields,
} from './config';
import { mapState } from 'vuex';

function bytesToSize(bytes) {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return {
    unit: i,
    unitText: sizes[i],
    size: Math.round(bytes / Math.pow(1024, i), 2),
  };
}

export default {
  components: {
    IconTrash,
    IconPencil,
  },
  filters: { germanDate },
  data() {
    return {
      fields: presseTableFields,
      items: [],
      filter: '',
      isLoading: false,
      inputPresse: {
        url: {
          value: '',
          col: '12',
          type: 'text',
          required: false,
          label: 'URL:',
          key: 'url',
        },
        filename : {
          value: null,
          col: '12',
          type: 'file',
          required: false,
          label: 'File:',
          key: 'filename',
        },
        information: {
          value: '',
          col: '12',
          type: 'textarea',
          required: true,
          label: 'Ihre Nachricht',
          key: 'information',
        },
      },
      isErrorInputPresse: {
        url: null,
        filename: null,
        information: null,
      },
      errorMsgInputPresse: {
        url: '',
        filename: '',
        information: '',
      },
      editId: 0,
      isEditOnlyInformation: true,
    };
  },
  computed: {
    ...mapState('admin', ['presseData']),
    isInputPresseFormError() {
      return Object.values(this.isErrorInputPresse).some((val) => val === false);
    },
  },
  created() {
    this.loadPresse();
  },
  methods: {
    isValidUrl(url) {
      let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return urlRegex.test(url);
    },
    validateInputPresse() {
      Object.values(this.inputPresse).forEach((item) => {
        if (item.required && item.value === '') {
          this.isErrorInputPresse = {
            ...this.isErrorInputPresse,
            [item.key]: false,
          };
          this.errorMsgInputPresse = {
            ...this.errorMsgInputPresse,
            [item.key]: 'Dieses Feld wird benötigt',
          };
        } else if (this.inputPresse.filename.value === null && this.inputPresse.url.value === '') {
          this.isErrorInputPresse = {
            ...this.isErrorInputPresse,
            filename: false,
            url: false,
          };
          this.errorMsgInputPresse = {
            ...this.errorMsgInputPresse,
            filename: 'Bitte fügen Sie mindestens eine URL oder Datei hinzu',
            url: 'Bitte fügen Sie mindestens eine URL oder Datei hinzu',
          };
        } else if (item.type === 'url' && !this.isValidUrl(item.value)) {
          this.isErrorInputPresse = {
            ...this.isErrorInputPresse,
            url: false,
          };
          this.errorMsgInputPresse = {
            ...this.errorMsgInputPresse,
            url: 'Bitte geben Sie eine gültige URL ein',
          };
        } else {
          this.isErrorInputPresse = {
            ...this.isErrorInputPresse,
            [item.key]: null,
          };
          this.errorMsgInputPresse = {
            ...this.errorMsgInputPresse,
            [item.key]: '',
          };
        }
      });
    },
    onCreatePresse() {
      this.resetInputPresse();
      this.$bvModal.show('admin-add-presse-modal');
    },
    handleUrlAndFileInput() {
      this.isErrorInputPresse.filename = null;
      this.errorMsgInputPresse.filename = '';
      this.isErrorInputPresse.url = null;
      this.errorMsgInputPresse.url = '';
      this.isEditOnlyInformation = false;
    },
    handleFileInput() {
      this.isErrorInputPresse.filename = null;
      this.errorMsgInputPresse.filename = '';
      this.isErrorInputPresse.url = null;
      this.errorMsgInputPresse.url = '';
      this.isEditOnlyInformation = false;

      if (this.inputPresse.filename.value) {
        const allowTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const selectType = this.inputPresse.filename.value.type;

        const { size, unit, unitText } = bytesToSize(this.inputPresse.filename.value.size);

        if (!allowTypes.includes(selectType)) {
          this.$bvModal.msgBoxOk('akzeptieren nur PNG, JPG, PDF', {
            title: 'Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then(() => {
            this.inputPresse.filename.value = null;
          });
        } else if (size > 5 && unit > 1) {
          this.$bvModal.msgBoxOk(`Die Dateigröße ist zu groß - Maximum 5 MB (Ihre Dateigröße: ${size} ${unitText})`, {
            title: 'Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then(() => {
            this.inputPresse.filename.value = null;
          });
        }
      }
    },
    async onSubmitAddPresse() {
      this.validateInputPresse();

      if (this.isInputPresseFormError) {
        return;
      }

      loader.start();
      let response;

      try {
        if (this.inputPresse.url.value === '') {
          response = await AdminInformation.createNewsFile({
            filename: this.inputPresse.filename.value,
            information: this.inputPresse.information.value,
          });
        } else {
          response =  await AdminInformation.createNews({
            url: this.inputPresse.url.value,
            information: this.inputPresse.information.value,
          });
        }
        loader.stop();

        if (response.status === 413) {
          this.$bvModal.msgBoxOk('Dateigröße ist zu groß. Bitte versuche es erneut.', {
            title: 'Falsche Dateigröße',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        if (response.status === 400) {
          this.$bvModal.msgBoxOk('Größe der hochgeladenen Datei überschreitet. Maximal 5MB.', {
            title: 'Upload fehlgeschlagen',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        if (response.status === 200 && response.data.successful === false) {
          this.$bvModal.msgBoxOk('Presse hinzufügen Fehler', {
            title: 'Presse hinzufügen Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        this.resetInputPresse();
        this.loadPresse();
        this.$bvModal.msgBoxOk('Presse hinzufügen Erfolgreich', {
          title: 'Presse hinzufügen',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      } catch (err) {
        loader.stop();
        if (err.response.status === 413) {
          this.$bvModal.msgBoxOk('Dateigröße ist zu groß. Bitte versuche es erneut.', {
            title: 'Falsche Dateigröße',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        this.$bvModal.msgBoxOk(err, {
          title: 'Presse hinzufügen Fehler',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }

      this.$bvModal.hide('admin-add-presse-modal');
    },
    async onSubmitEditPresse() {
      this.validateInputPresse();

      if (this.isInputPresseFormError) {
        return;
      }

      loader.start();
      let response;
      let payload = {};

      try {
        if (this.isEditOnlyInformation) {
          payload = {
            information: this.inputPresse.information.value,
          };
        } else if (this.inputPresse.url.value === '') {
          payload = {
            filename: this.inputPresse.filename.value,
            information: this.inputPresse.information.value,
          };
        } else {
          payload = {
            url: this.inputPresse.url.value,
            information: this.inputPresse.information.value,
          };
        }

        response = await this.$store.dispatch('admin/alterNews', {
          identification: this.editId,
          filterData: {
            ...payload,
          },
        });

        loader.stop();

        if (response.status === 200 && response.data.successful === false) {
          this.$bvModal.msgBoxOk('Edit Fehler', {
            title: 'Edit Presse hinzufügen Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        this.resetInputPresse();
        this.loadPresse();
        this.$bvModal.msgBoxOk('Edit Success', {
          title: 'Edit Presse hinzufügen',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      } catch (err) {
        loader.stop();
        if (err.response.status === 413) {
          this.$bvModal.msgBoxOk('Edit Dateigröße ist zu groß. Bitte versuche es erneut.', {
            title: 'Edit Falsche Dateigröße',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }

        this.$bvModal.msgBoxOk(err, {
          title: 'Edit Presse hinzufügen Fehler',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }

      this.$bvModal.hide('admin-edit-presse-modal');
    },
    onEdit(item) {
      this.resetInputPresse();

      this.editId = item.identification;
      Object.keys(this.inputPresse).forEach((itemKey) => {
        this.inputPresse = {
          ...this.inputPresse,
          [itemKey]: {
            ...this.inputPresse[itemKey],
            value: item[itemKey],
          },
        };
      });

      this.$bvModal.show('admin-edit-presse-modal');
    },
    onDelete(id) {
      this.$bvModal.msgBoxConfirm('Bist Du sicher? Das Löschen kann nicht rückgängig gemacht werden.', {
        title: 'Presse löschen',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
        cancelTitle: 'Abbrechen',
      })
        .then(async (val) => {
          if (!val) {
            return;
          }
          loader.start();
          try {
            await this.$store.dispatch('admin/removePresse', {
              identification: id,
            });
            loader.stop();
            this.items = this.presseData;
            this.$bvModal.msgBoxOk('Artikel wird gelöscht', {
              title: 'Erfolgreich löschen',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          } catch (err) {
            loader.stop();
            this.$bvModal.msgBoxOk(err, {
              title: 'Fehler löschen',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        });
    },
    async onDownloadItem(item) {
      loader.start();
      // const formData = new FormData();
      const data = new URLSearchParams();
      data.append('identification', item.identification);
      fetch('/api/download/', {
        method: 'post',
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', item.filename);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
          loader.stop();
        })
        .catch((err) => {
          loader.stop();
          this.$bvModal.msgBoxOk(err, {
            title: 'Download Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
        });
    },
    async loadPresse() {
      this.isLoading = true;
      await this.$store.dispatch('admin/showPresse');
      this.items = this.presseData;
      this.isLoading = false;
    },
    resetInputPresse() {
      Object.values(this.inputPresse).forEach((item) => {
        if (item.type === 'file') {
          this.inputPresse = {
            ...this.inputPresse,
            [item.key]: {
              ...item,
              value: null,
            },
          };
        } else {
          this.inputPresse = {
            ...this.inputPresse,
            [item.key]: {
              ...item,
              value: '',
            },
          };
        }
      });
      Object.keys(this.isErrorInputPresse).forEach((key) => {
        this.isErrorInputPresse = {
          ...this.isErrorInputPresse,
          [key]: null,
        };
      });
    },
  },
};
</script>